<template>
  <div class="budget-wizard">
    <v-stepper v-model="currentStep" v-if="clusterId">
      <v-stepper-header>
        <v-stepper-step :complete="currentStep > 1" step="1" color="pink"
          >Genel</v-stepper-step
        >
        <v-stepper-step :complete="currentStep > 2" step="2" color="pink"
          >Kalemler</v-stepper-step
        >
        <v-stepper-step :complete="currentStep > 3" step="3" color="pink"
          >Dağıtım</v-stepper-step
        >
        <v-stepper-step :complete="currentStep > 4" step="4" color="pink"
          >Tutarlar</v-stepper-step
        >
        <v-stepper-step :complete="currentStep > 5" step="5" color="pink"
          >Muafiyetler</v-stepper-step
        >
        <v-stepper-step :complete="currentStep > 6" step="6" color="pink"
          >Diğer Gelirler</v-stepper-step
        >
        <v-stepper-step :complete="currentStep > 7" step="7" color="pink"
          >Hesaplama</v-stepper-step
        >
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-row justify="center">
            <v-col lg="9">
              <v-alert type="info">
                <p>
                  Bütçe sihirbazına hoş geldiniz! Gelecek dönem gider
                  hesaplaması yapmak istiyorsanız bu sihirbazı kullanarak
                  bütçenizi oluşturabilirsiniz.
                </p>
                <p>
                  Bu aşamada bütçenizle alakalı temel bilgileri girmelisiniz.
                </p>
              </v-alert>
              <v-form
                v-if="clusterId"
                ref="formStep1"
                @submit.prevent="handleFormSubmit"
                :disabled="isLoading || formData.is_active"
              >
                <v-row>
                  <v-col cols="12" sm="6">
                    <rs-select
                      label="Borçlandırma Türü"
                      :rules="[rules.required]"
                      v-model="formData.income_type_id"
                      :items="incomeTypeList"
                      :readonly="formData.is_active"
                      :filled="formData.is_active"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <rs-text-field
                      label="Adı"
                      v-model="formData.name"
                      :rules="[
                        rules.required,
                        rules.minLength(formData.name, 1),
                        rules.maxLength(formData.name, 100),
                      ]"
                      :readonly="formData.is_active"
                      :filled="formData.is_active"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <rs-datepicker
                      label="Başlangıç Tarihi"
                      v-model="formData.starts_on"
                      :rules="formData.is_active ? [] : [rules.required]"
                      :disabled="formData.is_active"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <rs-datepicker
                      label="Bitiş Tarihi"
                      v-model="formData.ends_on"
                      :disabled="formData.is_active || !formData.starts_on"
                      :rules="
                        formData.is_active || !formData.starts_on
                          ? []
                          : [
                              rules.required,
                              rules.min(formData.ends_on, formData.starts_on),
                            ]
                      "
                      :min="formData.starts_on"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <rs-select-block
                      class="pb-4"
                      v-model="formData.block_ids"
                      :disabled="formData.is_active"
                      multiple
                      hint="Boş bırakılırsa tüm bloklar için bütçe yapılır."
                      persistent-hint
                      clearable
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-alert type="info">
            <p>Bütçenizde hangi giderlerin olması gerekiyor?</p>
            <p>
              Bu aşamada bütçenize giderlerinizi türlere ve isterseniz tiplere
              göre ekleyebilirsiniz. Her bir satır bir bütçe kalemi olacak
              şekilde düzenleyebilirsiniz.
            </p>
          </v-alert>

          <v-data-table
            :headers="headersFiltered"
            :items="itemList"
            :items-per-page="100"
            class="elevation-1"
            loading="isLoading"
            v-if="currentStep === 2"
            v-bind="dataTableAttrs"
          >
            <!-- eslint-disable-next-line -->
            <template v-slot:item.distribution_type_name="{ item }">
              <rs-select
                :items="budgetDistributionTypeList"
                v-model="item.distribution_type_id"
                :rules="[rules.required]"
              />
            </template>

            <!-- eslint-disable-next-line -->
            <template v-slot:item.expense_type_name="{ item }">
              <rs-select-expense-type
                v-model="item.expense_type_id"
                :rules="[rules.required]"
                @change="getItemPreviousAmount(item)"
              />
            </template>

            <!-- eslint-disable-next-line -->
            <template v-slot:item.child_expense_type_name="{ item }">
              <rs-select-expense-type
                :disabled="item.expense_type_id === null"
                v-model="item.child_expense_type_id"
                :children-of="item.expense_type_id"
                @change="handleChildExpenseTypeChange(item)"
              />
            </template>

            <!-- eslint-disable-next-line -->
            <template v-slot:item.previous_amount="{ value, item }">
              <strong><rs-table-cell-number price :value="value" /></strong
              ><br />
              <rs-table-cell-number
                price
                :value="item.previous_amount_monthly"
              />
              /ay
            </template>

            <!-- eslint-disable-next-line -->
            <template v-slot:item.actions="{ item }">
              <rs-table-button-delete
                @deleted="handleDeleteItemClick(item)"
                confirmed
                :loading="isLoading"
              />
            </template>

            <!-- eslint-disable-next-line -->
            <template v-slot:header.actions="{ item }">
              <rs-action
                @click="handleAddClick"
                :disabled="isLoading"
                :loading="isLoading"
              >
                Kalem Ekle
              </rs-action>
            </template>
          </v-data-table>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-alert type="info">
            <p>
              Bütçe kalemlerinizi belirlediniz. Şimdi bu
              <strong
                >giderlerin bağımsız bölümlere nasıl paylaştırılacağını
                seçmelisiniz.</strong
              >
            </p>
            <p>
              Seçeceğiniz dağıtım anahtarının tüm bağımsız bölümlerde girili
              olduğundan emin olmasınız. Örneğin bir gider kalemini net alana
              göre dağıtmak istiyorsanız tüm bağımsız bölümlerinizde
              <em>net alan</em> bilgisi girilmiş olmalıdır. Kontrol etmek için
              <router-link
                class="text-white text-decoration-underline"
                :to="{ name: 'definitions.houses.list' }"
                target="_blank"
                ><strong>bağımsız bölüm tanımları</strong
                ><v-icon>mdi-open-in-new</v-icon></router-link
              >
              listesine gidebilirsiniz.
            </p>
          </v-alert>

          <v-form ref="formStep3" :disabled="isLoading">
            <v-data-table
              :headers="headersFiltered"
              :items="itemList"
              :items-per-page="100"
              class="elevation-1"
              v-if="currentStep === 3"
              v-bind="dataTableAttrs"
            >
              <!-- eslint-disable-next-line -->
              <template v-slot:body.append="{ headers }">
                <rs-table-foot-totals
                  :headers="headers"
                  :totals="footTotals"
                  v-if="itemList.length > 0"
                />
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.distribution_type_name="{ item }">
                <rs-select
                  :items="budgetDistributionTypeList"
                  v-model="item.distribution_type_id"
                  :rules="[rules.required]"
                  @change="handleDistributionTypeChange(item)"
                />
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.expense_type_name="{ item }">
                <rs-select-expense-type
                  v-model="item.expense_type_id"
                  :rules="[rules.required]"
                  readonly
                  hide-add
                  :clearable="false"
                />
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.child_expense_type_name="{ item }">
                <rs-select-expense-type
                  v-model="item.child_expense_type_id"
                  :children-of="item.expense_type_id"
                  readonly
                  hide-add
                  :clearable="false"
                />
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.previous_amount="{ value, item }">
                <strong><rs-table-cell-number price :value="value" /></strong
                ><br />
                <rs-table-cell-number
                  price
                  :value="item.previous_amount_monthly"
                />
                /ay
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.actions="{ item }">
                <rs-table-button-delete
                  @deleted="handleDeleteItemClick(item)"
                  confirmed
                  :loading="isLoading"
                />
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:header.actions="{ item }">
                <rs-action
                  @click="handleAddClick"
                  :disabled="isLoading"
                  :loading="isLoading"
                >
                  Kalem Ekle
                </rs-action>
              </template>
            </v-data-table>
          </v-form>
        </v-stepper-content>

        <v-stepper-content step="4">
          <v-alert type="info">
            <p>Tutarları belirleyin.</p>
            <p>
              Bütçe kalemlerinizi ve bunların bağımsız bölümlere nasıl
              dağıtılacağını belirlediriniz. Şimdi her kalem gider için ne kadar
              harcamayı planladığınızı belirlemelisiniz.
            </p>
            <p>
              İsterseniz <em>artış oranı</em> alanından geçmiş döneme göre
              giderinizinin ne kadar değişeceğini belirleyebilirsiniz. Doğrudan
              gider tutarını belirlemek isterseniz
              <em>planlanan tutar</em> alanını kullanabilirsiniz.
            </p>
          </v-alert>

          <v-form ref="formStep4" :disabled="isLoading">
            <v-data-table
              :headers="headersFiltered"
              :items="itemList"
              :items-per-page="100"
              class="elevation-1"
              v-if="currentStep === 4"
              v-bind="dataTableAttrs"
            >
              <!-- eslint-disable-next-line -->
              <template v-slot:body.append="{ headers }">
                <rs-table-foot-totals
                  :headers="headers"
                  :totals="footTotals"
                  v-if="itemList.length > 0"
                >
                  <!-- eslint-disable-next-line -->
                  <template v-slot:foot.increase_rate>
                    <rs-text-field
                      type="number"
                      min="-1000"
                      max="9999999"
                      step="1"
                      label="Ortalama Artış Oranı"
                      v-model="footTotals.increase_rate"
                      :rules="[
                        rules.min(footTotals.increase_rate | null, -1000),
                        rules.max(footTotals.increase_rate | null, 9999999),
                      ]"
                      :disabled="isLoading"
                      prefix="%"
                      @change="handleIncreaseRateTotalChange()"
                      style="min-width: 150px"
                    />
                  </template>

                  <!-- eslint-disable-next-line -->
                  <template v-slot:foot.error_margin>
                    <rs-text-field
                      type="number"
                      min="-1000"
                      max="9999999"
                      step="1"
                      label="Ortalama Hata Payı"
                      v-model="footTotals.error_margin"
                      :rules="[
                        rules.min(footTotals.error_margin || null, -1000),
                        rules.max(footTotals.error_margin || null, 9999999),
                      ]"
                      :disabled="isLoading"
                      prefix="%"
                      @change="handleErrorMarginTotalChange()"
                      style="min-width: 150px"
                    />
                  </template>
                </rs-table-foot-totals>
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.distribution_type_name="{ item }">
                <rs-select
                  :items="budgetDistributionTypeList"
                  v-model="item.distribution_type_id"
                  :rules="[rules.required]"
                  readonly
                  :clearable="false"
                />
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.expense_type_name="{ item }">
                <rs-select-expense-type
                  v-model="item.expense_type_id"
                  :rules="[rules.required]"
                  readonly
                  hide-add
                  :clearable="false"
                />
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.child_expense_type_name="{ item }">
                <rs-select-expense-type
                  v-model="item.child_expense_type_id"
                  :children-of="item.expense_type_id"
                  readonly
                  hide-add
                  :clearable="false"
                />
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.amount="{ item }">
                <rs-money-field
                  label="Tutar"
                  v-model="item.amount"
                  :rules="[
                    rules.required,
                    rules.min(item.amount, 0.01),
                    rules.max(item.amount, 9999999.99),
                  ]"
                  :disabled="isLoading"
                  @change="handleAmountChange(item)"
                  style="min-width: 150px"
                /><br />
                <rs-table-cell-number price :value="item.amount / 12" /> /ay
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.increase_rate="{ item }">
                <rs-text-field
                  v-if="item.previous_amount"
                  type="number"
                  min="-1000"
                  max="9999999"
                  step="1"
                  label="Artış Oranı"
                  v-model="item.increase_rate"
                  :rules="[
                    rules.min(item.increase_rate, -1000),
                    rules.max(item.increase_rate, 9999999),
                  ]"
                  :disabled="isLoading"
                  prefix="%"
                  @change="handleIncreaseRateChange(item)"
                  style="min-width: 150px"
                />
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.error_margin="{ item }">
                <rs-text-field
                  type="number"
                  min="0"
                  max="999"
                  step="1"
                  label="Hata Payı"
                  v-model="item.error_margin"
                  :rules="[
                    rules.min(item.error_margin, 0),
                    rules.max(item.error_margin, 999),
                  ]"
                  :disabled="isLoading"
                  prefix="%"
                  style="min-width: 100px"
                />
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.previous_amount="{ value, item }">
                <strong><rs-table-cell-number price :value="value" /></strong
                ><br />
                <rs-table-cell-number
                  price
                  :value="item.previous_amount_monthly"
                />
                /ay
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.actions="{ item }">
                <!-- <rs-table-button-delete
                  @deleted="handleDeleteItemClick(item)"
                  confirmed
                  :loading="isLoading"
                /> -->
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:header.actions="{ item }">
                <rs-action
                  @click="handleAddClick"
                  :disabled="isLoading"
                  :loading="isLoading"
                >
                  Kalem Ekle
                </rs-action>
              </template>
            </v-data-table>
          </v-form>
        </v-stepper-content>

        <v-stepper-content step="5">
          <v-alert type="info">
            <p>
              Toplu yaşam alanınızda giderlerden kısmen veya tamamen muaf
              bağımsız bölümler var mı? Eğer varsa bu aşamada bu bağımsız
              bölümleri seçebilirsiniz.
            </p>
            <p>
              Bir bağımsız bölümü sadece belli bütçe kalemlerinden muaf tutmak
              isterseniz bunları seçebilirsiniz. Seçmezseniz tüm bütçe
              kalemlerinden muaf olacaktır.
            </p>
            <p>
              Muafiyet oranını %1 ile %100 arasında belirleyebilirsiniz. Örneğin
              muafiyet oranını %50 olarak belirlerseniz bu bağımsız bölüm
              giderlerin yarısından muaf olacaktır.
            </p>
          </v-alert>

          <v-form ref="formStep5" :disabled="isLoading">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">Bağımsız Bölüm</th>
                    <th class="text-center">Bütçe Kalemleri</th>
                    <th class="text-center" style="min-width: 220px">
                      Muafiyet Oranı
                    </th>
                    <th class="text-center">
                      <rs-action
                        @click="handleAddBudgetExemptClick"
                        :disabled="isLoading"
                        :loading="isLoading"
                      >
                        <v-icon>mdi-plus</v-icon> Ekle
                      </rs-action>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in budgetExemptionList" :key="index">
                    <td>
                      <rs-select-house
                        v-model="item.house_id"
                        :cluster-id="clusterId"
                        :rules="[rules.required]"
                        :disabled="isLoading"
                      />
                    </td>
                    <td>
                      <rs-select
                        multiple
                        :label="
                          !item.expense_type_ids?.length
                            ? 'Tümü'
                            : 'Bütçe Kalemleri'
                        "
                        v-model="item.expense_type_ids"
                        :items="availableExpenseTypeList"
                        :disabled="isLoading"
                      />
                    </td>
                    <td>
                      <v-slider
                        v-model="item.exemption_ratio"
                        min="1"
                        max="100"
                        step="1"
                        ticks
                        thumb-label="always"
                        :rules="[rules.required]"
                      >
                        <template v-slot:thumb-label="{ value }"
                          >%{{ value }}</template
                        >
                      </v-slider>
                      <!-- <rs-text-field
                          label="Muafiyet Oranı"
                          prefix="%"
                          v-model="item.exemption_ratio"
                          :rules="[
                            rules.required,
                            rules.min(item.exemption_ratio, 1),
                            rules.max(item.exemption_ratio, 100),
                          ]"
                          :disabled="isLoading"
                        /> -->
                    </td>
                    <td class="text-end">
                      <rs-table-button-delete
                        @deleted="handleDeleteBudgetExemptClick(item)"
                        confirmed
                        :loading="isLoading"
                        icon-only
                      />
                    </td>
                  </tr>
                  <tr v-if="budgetExemptionList.length === 0">
                    <td colspan="4" class="text-center">
                      <em>Muaf bağımsız bölüm yok.</em>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-form>
        </v-stepper-content>

        <v-stepper-content step="6">
          <v-alert type="info">
            <p>
              İsterseniz bütçenize gelir kaynağı olarak diğer gelirleri de
              ekleyebilirsiniz.
              <template v-if="positiveExpenseTypeList?.length > 0">
                Örnek:
                {{
                  positiveExpenseTypeList
                    .slice(0, 3)
                    .map((v) => v.name)
                    .join(", ")
                }}...
              </template>
            </p>
          </v-alert>

          <v-form ref="formStep6" :disabled="isLoading">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">Diğer Gelir Türü</th>
                    <th class="text-center">Bağıtım Anahtarı</th>
                    <th class="text-center">Geçmiş Dönem Toplam</th>
                    <th class="text-center">Artış Oranı</th>
                    <th class="text-center">Tutar</th>
                    <th class="text-center">
                      <rs-action
                        @click="handleAddOtherIncomeClick"
                        :disabled="isLoading"
                        :loading="isLoading"
                      >
                        <v-icon>mdi-plus</v-icon> Ekle
                      </rs-action>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in otherIncomeList" :key="index">
                    <td>
                      <rs-select
                        label="Diğer Gelir Türü"
                        v-model="item.expense_type_id"
                        :rules="[rules.required]"
                        :items="positiveExpenseTypeList"
                        :filled="disabled"
                        :readonly="disabled"
                        @change="getOtherIncomeItemPreviousAmount(item)"
                      >
                        <template
                          slot="append-outer"
                          v-if="!item.expense_type_id || !disabled"
                        >
                          <v-btn
                            small
                            outlined
                            :to="{
                              name: 'other-definitions.other-incomes.create',
                            }"
                            color="pink"
                            title="Yeni diğer gelir türü eklemek için tıklayın"
                          >
                            Ekle
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                      </rs-select>
                    </td>
                    <td>
                      <rs-select
                        :items="budgetDistributionTypeList"
                        v-model="item.distribution_type_id"
                        :rules="[rules.required]"
                        readonly
                        :clearable="false"
                      />
                    </td>
                    <td>
                      <strong
                        ><rs-table-cell-number
                          price
                          :value="item.previous_amount" /></strong
                      ><br />
                      <rs-table-cell-number
                        price
                        :value="item.previous_amount_monthly"
                      />
                      /ay
                    </td>
                    <td>
                      <rs-text-field
                        type="number"
                        min="-1000"
                        max="9999999"
                        step="1"
                        label="Artış Oranı"
                        v-model="item.increase_rate"
                        :rules="[
                          rules.min(item.increase_rate, -1000),
                          rules.max(item.increase_rate, 999999),
                        ]"
                        :disabled="isLoading"
                        prefix="%"
                        @change="handleIncreaseRateChange(item)"
                        style="min-width: 150px"
                      />
                    </td>
                    <td>
                      <rs-money-field
                        label="Tutar"
                        v-model="item.amount"
                        :rules="[
                          rules.required,
                          rules.min(item.amount, 0.01),
                          rules.max(item.amount, 9999999.99),
                        ]"
                        :disabled="isLoading"
                        @change="handleAmountChange(item)"
                        style="min-width: 150px"
                      /><br />
                      <rs-table-cell-number price :value="item.amount / 12" />
                      /ay
                    </td>
                    <td class="text-end">
                      <rs-table-button-delete
                        @deleted="handleDeleteOtherIncomeClick(item)"
                        confirmed
                        :loading="isLoading"
                        icon-only
                      />
                    </td>
                  </tr>
                  <tr v-if="otherIncomeList.length === 0">
                    <td colspan="6" class="text-center">
                      <em>Diğer gelir yok.</em>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-form>
        </v-stepper-content>

        <v-stepper-content step="7">
          <v-alert type="info">
            <p>Bütçenizi tamamladınız!</p>
            <p v-if="!id">
              Bütçenizi hesaplamaya geçmeden önce kaydetmelisiniz.
            </p>
            <p v-if="id">
              Bütçe hesaplamanızı isterseniz hemen yapabilirsiniz. Eğer
              hesaplama yapmadan önce
              <router-link
                class="text-light text-decoration-underline"
                :to="{ name: 'budget.definition.edit', params: { id: id } }"
                >kontrol etmek veya düzenlemeye devam etmek isterseniz bütçe
                detay sayfasına göz gezdirebilirsiniz</router-link
              >.
            </p>
          </v-alert>
        </v-stepper-content>
      </v-stepper-items>

      <div class="text-center p-5">
        <rs-action
          large
          @click="prevStep"
          :disabled="currentStep === 1"
          v-if="currentStep !== 7"
          :is-loading="isLoading"
          >Geri Dön</rs-action
        >
        <rs-action
          :is-loading="isLoading"
          large
          @click="nextStep"
          v-if="currentStep < 7"
          >Devam</rs-action
        >
        <rs-action
          :is-loading="isLoading"
          large
          @click="saveBudget"
          v-if="currentStep === 7 && !id"
          >Bütçeyi Kaydet</rs-action
        >

        <rs-action
          :is-loading="isLoading"
          large
          v-if="currentStep === 7 && id"
          @click="bottomSheet = true"
          >Hesapla!</rs-action
        >

        <v-bottom-sheet v-model="bottomSheet">
          <v-sheet class="text-center p-10">
            <v-alert type="info">
              <p>İki farklı hesaplama yapabilirsiniz:</p>
              <p>
                Her <strong>bağımsız bölüm için</strong> ayrı tutarda
                borçlandırma yapmak istiyorsanız
                <strong>Her Bağımsız Bölüm için Hesapla</strong> tuşuna
                tıklayın.
              </p>
              <p>
                Her <strong>bağımsız bölüm tipi için</strong> ayrı tutarda
                borçlandırma yapmak istiyorsanız
                <strong>Bağımsız Bölüm Tipleri için Hesapla</strong> tuşuna
                tıklayın.
              </p>
            </v-alert>

            <rs-action
              :is-loading="isLoading"
              large
              @click="handleCalculateHouseClick"
              >Her Bağımsız Bölüm için Hesapla</rs-action
            >

            <rs-action
              :is-loading="isLoading"
              large
              @click="handleCalculateHouseTypeClick"
              >Bağımsız Bölüm Tipleri için Hesapla</rs-action
            >
          </v-sheet>
        </v-bottom-sheet>
      </div>
    </v-stepper>

    <div v-else>
      <v-alert type="error">
        <p>Lütfen bir TYA seçiniz.</p>
      </v-alert>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { hasDataTable, hasForm, isPage } from "@/view/mixins";
import ClusterService from "@/core/services/cluster.service";

export default {
  mixins: [hasDataTable, hasForm, isPage],
  computed: {
    ...mapGetters([
      "budgetDistributionTypeList",
      "clusterId",
      "expenseTypeList",
      "incomeTypeList",
      "matomoReadonlyAuthToken",
      "positiveExpenseTypeList",
    ]),
    headersFiltered() {
      const headers = [];

      for (const index in this.headers) {
        const header = this.headers[index];

        if (header.setClass) {
          const cssClass = header.setClass();
          header.class = cssClass;
          header.cellClass = cssClass;
        }

        if (header.steps) {
          if (header.steps.includes(this.currentStep)) {
            headers.push(this.headers[index]);
          }
        } else {
          headers.push(header);
        }
      }

      return headers;
    },
    availableExpenseTypeList() {
      const ıds = this.itemList.map((item) => item.expense_type_id);
      return this.expenseTypeList.filter((expenseType) => {
        return ıds.includes(expenseType.id);
      });
    },
  },
  watch: {
    itemList: {
      handler() {
        const footTotals = {
          previous_amount: 0,
          amount: 0,
          increase_rate: null,
          error_margin: null,
        };

        let count = 0;
        let totalErrorMargin = 0;

        for (const item of this.itemList) {
          footTotals.previous_amount += item.previous_amount;
          footTotals.amount += item.amount;
          totalErrorMargin += parseFloat(item.error_margin);
          count++;
        }

        footTotals.increase_rate =
          (footTotals.amount / footTotals.previous_amount) * 100 - 100;

        footTotals.increase_rate = parseFloat(
          footTotals.increase_rate.toFixed(2)
        );

        footTotals.error_margin = totalErrorMargin / count;
        footTotals.error_margin = parseFloat(
          footTotals.error_margin.toFixed(2)
        );

        this.footTotals = footTotals;
      },
      deep: true,
    },
  },
  data() {
    return {
      pageMeta: {
        title: "Bütçe Sihirbazı",
      },
      currentStep: 1,
      id: null,
      bottomSheet: false,
      formData: {
        name: null,
        starts_on: null,
        ends_on: null,
        income_type_id: null,
        block_ids: [],
      },
      itemList: [],
      budgetExemptionList: [],
      otherIncomeList: [],
      selectedExemptionTab: null,
      dataTableAttrs: {
        showSelect: false,
        disableSort: true,
      },
      footTotals: {},
      headers: [
        {
          text: "Gider Türü",
          value: "expense_type_name",
          searchValue: "expense_type_ids",
          searchable: "multiselect",
          options: () => this.expenseTypeList,
          // setClass: () => this.currentStep === 2 && "highlighted",
        },
        {
          text: "Gider Tipi",
          value: "child_expense_type_name",
          searchable: "text",
          // setClass: () => this.currentStep === 2 && "highlighted",
        },
        {
          text: "Dağıtım Anahtarı",
          value: "distribution_type_name",
          searchValue: "distribution_type_ids",
          searchable: "multiselect",
          options: () => this.budgetDistributionTypeList,
          align: "center",
          steps: [3, 4, 5, 6, 7],
          setClass: () => this.currentStep === 3 && "highlighted",
        },
        {
          text: "Geçmiş Dönem Fatura Toplamı",
          value: "previous_amount",
          searchable: "number",
          align: "end",
          width: "180px",
          steps: [3, 4, 6, 7],
        },
        {
          text: "Artış Oranı",
          value: "increase_rate",
          searchable: "number",
          align: "end",
          steps: [4, 5, 6, 7],
          setClass: () => this.currentStep === 4 && "highlighted",
          notPrice: true,
        },
        {
          text: "Planlanan Toplam Tutar",
          value: "amount",
          searchable: "number",
          align: "end",
          steps: [4, 5, 6, 7],
          setClass: () => this.currentStep === 4 && "highlighted",
        },
        {
          text: "Hata Payı",
          value: "error_margin",
          searchable: "number",
          align: "end",
          steps: [4, 5, 6, 7],
          setClass: () => this.currentStep === 4 && "highlighted",
        },
        {
          text: "",
          value: "actions",
          align: "end",
          searchable: false,
          sortable: false,
          steps: [2],
          setClass: () => this.currentStep === 5 && "highlighted",
        },
      ],
    };
  },
  methods: {
    prevStep() {
      if (this.currentStep > 1) this.currentStep -= 1;
    },
    async nextStep() {
      if (this.currentStep === 1) {
        if (!this.$refs.formStep1.validate()) {
          return;
        }
      } else if (this.currentStep === 2) {
        for (const index in this.itemList) {
          if (!this.itemList[index].expense_type_id) {
            this.$toast.error("Tüm gider türlerini seçmelisiniz.");
            return false;
          }
        }

        if (this.itemList.length === 0) {
          this.$toast.error("En az bir gider kalemi eklemelisiniz.");
          return false;
        }
      } else if (this.currentStep === 3) {
        if (!this.$refs.formStep3.validate()) {
          return;
        }
      } else if (this.currentStep === 4) {
        if (!this.$refs.formStep4.validate()) {
          return;
        }

        for (const index in this.itemList) {
          const item = this.itemList[index];

          if (item.amount > 0) {
            continue;
          } else {
            this.$toast.error(
              "Tüm gider kalemlerinin planlanan tutarını giriniz."
            );
            return false;
          }
        }
      } else if (this.currentStep === 5) {
        // check for uniqueness of house_id in budgetExemptionList
        const houseIds = this.budgetExemptionList.map((item) => item.house_id);
        if (houseIds.length !== new Set(houseIds)?.size) {
          this.selectedExemptionTab = "budget-exemption";
          this.$toast.error(
            "Aynı bağımsız bölümü birden fazla ekleyemezsiniz."
          );
          return false;
        }

        if (!this.$refs.formStep5.validate()) {
          return;
        }
      } else if (this.currentStep === 6) {
        if (!this.$refs.formStep6.validate()) {
          return;
        }
      }

      if (this.currentStep < 7) this.currentStep += 1;

      if (this.currentStep === 2) {
        this.loadItemListIfEmpty();
      }

      if (this.currentStep === 6) {
        this.loadOtherIncomeListIfEmpty();
      }
    },
    handleAddClick() {
      const newItem = {
        expense_type_id: null,
        child_expense_type_id: null,
        distribution_type_id: 1,
        previous_amount: null,
        previous_amount_monthly: null,
        amount: null,
        increase_rate: null,
        error_margin: 10,
        exemptions: [],
      };

      this.itemList.push(newItem);
    },
    getChildExpenseTypeList(parentId) {
      if (!parentId) {
        return [];
      }

      return ClusterService.getExpenseTypeChildren(parentId);
    },
    loadItemListIfEmpty() {
      if (this.isLoading || !this.clusterId) {
        return;
      }

      const params = {
        cluster_id: this.clusterId,
        from: this.formData.starts_on,
        to: this.formData.ends_on,
      };

      this.isLoading = true;

      this.$api
        .query("budgets/previous-expense-type-list", { params })
        .then((response) => {
          this.itemList = response.data.data.map((item) => {
            item.distribution_type_id = 1;
            item.amount = null;
            item.increase_rate = null;
            item.exemptions = [];
            item.error_margin = 10;

            return item;
          });
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleDeleteItemClick(item) {
      this.itemList = this.itemList.filter((i) => i !== item);
    },
    getItemPreviousAmount(item) {
      if (this.isLoading || !this.clusterId) {
        return;
      }

      const params = {
        cluster_id: this.clusterId,
        from: this.formData.starts_on,
        to: this.formData.ends_on,
        expense_type_id: item.expense_type_id,
        child_expense_type_id: item.child_expense_type_id || null,
      };

      this.isLoading = true;

      this.$api
        .query("budgets/previous-expense-type-list", { params })
        .then((response) => {
          if (response.data.data.length) {
            item.previous_amount = response.data.data[0].previous_amount;
          } else {
            item.previous_amount = 0.0;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleChildExpenseTypeChange(item) {
      this.getItemPreviousAmount(item);

      for (const index in this.itemList) {
        const i = this.itemList[index];

        if (i.expense_type_id === item.expense_type_id) {
          i.distribution_type_id = item.distribution_type_id;
        }
      }
    },
    handleDistributionTypeChange(item) {
      for (const index in this.itemList) {
        const i = this.itemList[index];

        if (i.expense_type_id === item.expense_type_id) {
          i.distribution_type_id = item.distribution_type_id;
        }
      }
    },
    handleAmountChange(item) {
      if (item.amount && item.previous_amount) {
        const increaseRate =
          (item.amount * 100 - item.previous_amount * 100) /
          item.previous_amount;

        item.increase_rate = parseFloat(increaseRate.toFixed(2));
      } else {
        item.increase_rate = null;
      }
    },
    handleIncreaseRateChange(item) {
      if (item.increase_rate && item.previous_amount) {
        const amount = item.previous_amount * (1 + item.increase_rate / 100);
        item.amount = parseFloat(amount.toFixed(2));
      } else {
        item.amount = null;
      }
    },
    handleAddBudgetExemptClick() {
      this.budgetExemptionList.push({
        house_id: null,
        exemption_ratio: 100,
        expense_type_ids: [],
      });
    },
    handleDeleteBudgetExemptClick(item) {
      this.budgetExemptionList = this.budgetExemptionList.filter(
        (i) => i !== item
      );
    },
    loadOtherIncomeListIfEmpty() {
      if (this.isLoading || !this.clusterId) {
        return;
      }

      const params = {
        cluster_id: this.clusterId,
        from: this.formData.starts_on,
        to: this.formData.ends_on,
      };

      this.isLoading = true;

      this.$api
        .query("budgets/previous-other-income-list", { params })
        .then((response) => {
          this.otherIncomeList = response.data.data.map((item) => {
            item.distribution_type_id = 1;
            item.amount = null;
            item.increase_rate = null;

            return item;
          });
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleAddOtherIncomeClick() {
      const newItem = {
        expense_type_id: null,
        distribution_type_id: 1,
        previous_amount: null,
        amount: null,
        increase_rate: null,
      };

      this.otherIncomeList.push(newItem);
    },
    getOtherIncomeItemPreviousAmount(item) {
      if (this.isLoading || !this.clusterId) {
        return;
      }

      const params = {
        cluster_id: this.clusterId,
        from: this.formData.starts_on,
        to: this.formData.ends_on,
        expense_type_id: item.expense_type_id,
      };

      this.isLoading = true;

      this.$api
        .query("budgets/previous-other-income-list", { params })
        .then((response) => {
          if (response.data.data.length) {
            item.previous_amount = response.data.data[0].previous_amount;
            item.previous_amount_monthly = item.previous_amount / 12;
          } else {
            item.previous_amount = 0.0;
            item.previous_amount_monthly = 0.0;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleDeleteOtherIncomeClick(item) {
      this.otherIncomeList = this.otherIncomeList.filter((i) => i !== item);
    },
    saveBudget() {
      if (this.isLoading) {
        return Promise.reject();
      }

      const formData = {
        cluster_id: this.clusterId,
        name: this.formData.name,
        starts_on: this.formData.starts_on,
        ends_on: this.formData.ends_on,
        income_type_id: this.formData.income_type_id,
        items: this.itemList,
        other_incomes: this.otherIncomeList,
        exemptions: this.budgetExemptionList,
      };

      this.isLoading = true;

      return this.$api
        .post("budgets/save-all", formData)
        .then((response) => {
          this.id = response.data.data.id;

          return response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleCalculateHouseClick() {
      if (this.isLoading) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .post(`budgets/${this.id}/calculate`, {})
        .then(() => {
          this.$toast.success("Tebrikler! Bütçeniz hesaplandı.");
          setTimeout(() => {
            this.$router.replace({
              name: "budget.definition.edit",
              params: { id: this.id },
              hash: "#house-result",
            });
          }, 100);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleCalculateHouseTypeClick() {
      if (this.isLoading) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .post(`budgets/${this.id}/calculate`, {})
        .then(() => {
          this.$toast.success("Tebrikler! Bütçeniz hesaplandı.");
          setTimeout(() => {
            this.$router.replace({
              name: "budget.definition.edit",
              params: { id: this.id },
              hash: "#house-type-result",
            });
          }, 100);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleIncreaseRateTotalChange() {
      for (const index in this.itemList) {
        const i = this.itemList[index];

        if (i.previous_amount) {
          i.increase_rate = this.footTotals.increase_rate;
          i.amount = i.previous_amount * (1 + i.increase_rate / 100);
        }
      }
    },
    handleErrorMarginTotalChange() {
      for (const index in this.itemList) {
        const i = this.itemList[index];
        i.error_margin = this.footTotals.error_margin;
      }
    },
  },
};
</script>

<style>
.v-stepper__step {
  padding: 10px;
}

.budget-wizard .formatted-text {
  left: 0;
}

.budget-wizard .highlighted .formatted-text {
  background-color: rgb(251, 255, 216);
}
</style>
